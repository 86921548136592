import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { AlertService } from '../../services/alert.service';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  urlApi = environment.apiBlog;

  private listPost = new BehaviorSubject<[] | any>([])
  listPost$ = this.listPost.asObservable()

  private http = inject(HttpClient);
  private alertService = inject(AlertService)
  private router = inject(Router)

  getAll() {
    return this.http
    .get<[]>(this.urlApi.concat('post.json'))
    .subscribe(data => {
      let arrayData = Object.entries(data)
      if (arrayData.length) this.listPost.next(arrayData.reverse())
    }, () => {
      this.alertService.alert('error', 'Ha habido un error, intenta de nuevo mas tarde')
      this.router.navigate(['/'])
    })
  }

  create(body: {}) {
    return this.http
    .post(this.urlApi.concat('post.json'), body)
  }

}
